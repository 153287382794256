import { translatePath as _translatePath } from './translatePath'
import { translateApi as _translateApi } from './translateApi'

import FooterPT from './Footer.pt.json'
import FooterEn from './Footer.en.json'
import FooterEs from './Footer.es.json'
import MenuBarPt from './MenuBar.pt.json'
import MenuBarEn from './MenuBar.en.json'
import MenuBarEs from './MenuBar.es.json'
import HeadPt from './Head.pt.json'
import HeadEn from './Head.en.json'
import HeadEs from './Head.es.json'
import TestimonialsPt from './Testimonials.pt.json'
import TestimonialsEn from './Testimonials.en.json'
import TestimonialsEs from './Testimonials.es.json'
import ValuesTabsPt from './ValuesTabs.pt.json'
import ValuesTabsEn from './ValuesTabs.en.json'
import ValuesTabsEs from './ValuesTabs.es.json'
import JeitoDimasPt from './JeitoDimas.pt.json'
import JeitoDimasEn from './JeitoDimas.en.json'
import JeitoDimasEs from './JeitoDimas.es.json'
import FaqPt from './FAQ.pt.json'
import FaqEn from './FAQ.en.json'
import FaqEs from './FAQ.es.json'
import ContactPt from './Contact.pt.json'
import ContactEn from './Contact.en.json'
import ContactEs from './Contact.es.json'
import HomePt from './Home.pt.json'
import HomeEn from './Home.en.json'
import HomeEs from './Home.es.json'
import HandedOutPt from './HandedOut.pt.json'
import HandedOutEn from './HandedOut.en.json'
import HandedOutEs from './HandedOut.es.json'
import ReleasesPt from './Releases.pt.json'
import ReleasesEn from './Releases.en.json'
import ReleasesEs from './Releases.es.json'
import WorkWithUsPt from './WorkWithUs.pt.json'
import WorkWithUsEn from './WorkWithUs.en.json'
import WorkWithUsEs from './WorkWithUs.es.json'
import AcesseCondominioPt from './AcesseCondominio.pt.json'
import AcesseCondominioEn from './AcesseCondominio.en.json'
import AcesseCondominioEs from './AcesseCondominio.es.json'
import Page404Pt from './Page404.pt.json'
import Page404En from './Page404.en.json'
import Page404Es from './Page404.es.json'
import PageSucessPt from './PageSucess.pt.json'
import PageSucessEn from './PageSucess.en.json'
import PageSucessEs from './PageSucess.es.json'
import ReferralCampaignPt from './ReferralCampaign.pt.json'
import ReferralCampaignEn from './ReferralCampaign.en.json'
import ReferralCampaignEs from './ReferralCampaign.es.json'
import PageSucessCampaignPt from './PageSucessCampaign.pt.json'
import PageSucessCampaignEn from './PageSucessCampaign.en.json'
import PageSucessCampaignEs from './PageSucessCampaign.es.json'
import TermsPt from './Terms.pt.json'
import TermsEn from './Terms.en.json'
import TermsEs from './Terms.es.json'
import PolicyPt from './Policy.pt.json'
import PolicyEn from './Policy.en.json'
import PolicyEs from './Policy.es.json'

export const resources = {
  pt: {
    translation: {
      Footer: FooterPT,
      MenuBar: MenuBarPt,
      Head: HeadPt,
      Testimonials: TestimonialsPt,
      ValuesTabs: ValuesTabsPt,
      JeitoDimas: JeitoDimasPt,
      FAQ: FaqPt,
      Contact: ContactPt,
      Home: HomePt,
      HandedOut: HandedOutPt,
      Releases: ReleasesPt,
      WorkWithUs: WorkWithUsPt,
      AcesseCondominio: AcesseCondominioPt,
      Page404: Page404Pt,
      PageSucess: PageSucessPt,
      ReferralCampaign: ReferralCampaignPt,
      PageSucessCampaign: PageSucessCampaignPt,
      Terms: TermsPt,
      Policy: PolicyPt
    },
  },
  en: {
    translation: {
      Footer: FooterEn,
      MenuBar: MenuBarEn,
      Head: HeadEn,
      Testimonials: TestimonialsEn,
      ValuesTabs: ValuesTabsEn,
      JeitoDimas: JeitoDimasEn,
      FAQ: FaqEn,
      Contact: ContactEn,
      Home: HomeEn,
      HandedOut: HandedOutEn,
      Releases: ReleasesEn,
      WorkWithUs: WorkWithUsEn,
      AcesseCondominio: AcesseCondominioEn,
      Page404: Page404En,
      PageSucess: PageSucessEn,
      ReferralCampaign: ReferralCampaignEn,
      PageSucessCampaign: PageSucessCampaignEn,
      Terms: TermsEn,
      Policy: PolicyEn,
    },
  },
  es: {
    translation: {
      Footer: FooterEs,
      MenuBar: MenuBarEs,
      Head: HeadEs,
      Testimonials: TestimonialsEs,
      ValuesTabs: ValuesTabsEs,
      JeitoDimas: JeitoDimasEs,
      FAQ: FaqEs,
      Contact: ContactEs,
      Home: HomeEs,
      HandedOut: HandedOutEs,
      Releases: ReleasesEs,
      WorkWithUs: WorkWithUsEs,
      AcesseCondominio: AcesseCondominioEs,
      Page404: Page404Es,
      PageSucess: PageSucessEs,
      ReferralCampaign: ReferralCampaignEs,
      PageSucessCampaign: PageSucessCampaignEs,
      Terms: TermsEs,
      Policy: PolicyEs
    },
  },
}

export const translatePath = _translatePath as (path: string) => string
export const translateApi = _translateApi as <
  DataType extends object,
  PropNameType extends keyof DataType & string
>(
  data: DataType,
  propName: PropNameType
) => DataType[PropNameType]

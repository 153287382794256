exports.translatePath = (pathname, withPrefix = false) => {
  if (
    process.env.GATSBY_LANGUAGE !== 'en' &&
    process.env.GATSBY_LANGUAGE !== 'es'
  )
    return pathname

  const prefix = !withPrefix
    ? ''
    : process.env.GATSBY_LANGUAGE === 'en'
    ? '/en'
    : '/es'
  let ret = pathname

  if (pathname === '/sucesso/')
    ret = process.env.GATSBY_LANGUAGE === 'en' ? '/success/' : '/exito/'
  else if (pathname === '/politica-de-privacidade/')
    ret =
      process.env.GATSBY_LANGUAGE === 'en'
        ? '/privacy-policy/'
        : '/politica-de-privacidad/'
  else if (pathname === '/termos-de-uso/')
    ret =
      process.env.GATSBY_LANGUAGE === 'en'
        ? '/terms-of-use/'
        : '/terminos-de-uso/'
  else if (pathname === '/jeito-dimas/')
    ret =
      process.env.GATSBY_LANGUAGE === 'en' ? '/dimas-way/' : '/camino-dimas/'
  else if (pathname === '/acesse-condominio/')
    ret =
      process.env.GATSBY_LANGUAGE === 'en'
        ? '/access-condominium/'
        : '/acceso-condominio/'
  else if (pathname === '/contato/')
    ret = process.env.GATSBY_LANGUAGE === 'en' ? '/contact/' : '/contacto/'
  else if (pathname === '/contato/faq/')
    ret =
      process.env.GATSBY_LANGUAGE === 'en' ? '/contact/faq/' : '/contacto/faq/'
  else if (pathname === '/contato/trabalhe-conosco/')
    ret =
      process.env.GATSBY_LANGUAGE === 'en'
        ? '/contact/work-with-us/'
        : '/contacto/trabaja-con-nosotros/'
  else if (pathname === '/empreendimentos/')
    ret = process.env.GATSBY_LANGUAGE === 'en' ? '/enterprises/' : '/empresas/'
  else if (pathname === '/empreendimentos/entregues/')
    ret =
      process.env.GATSBY_LANGUAGE === 'en'
        ? '/enterprises/handed/'
        : '/empresas/entrego/'
  else if (pathname === '/empreendimentos/lancamentos/')
    ret =
      process.env.GATSBY_LANGUAGE === 'en'
        ? '/enterprises/releases/'
        : '/empresas/lanzamientos/'
  else if (pathname === '/campanha-de-indicacao/')
    ret =
      process.env.GATSBY_LANGUAGE === 'en' ? '/referral-campaign/' : '/campana-de-referencia/'
  else if (pathname === '/sucesso-campanha/')
    ret =
      process.env.GATSBY_LANGUAGE === 'en' ? '/campaign-success/' : '/exito-campana/'

  return prefix + ret
}

import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'
import cn from 'classnames'
import * as st from '@assets/styl/menubar.module.styl'
import { useLanguage } from '../hooks'
import { translatePath } from '../translations'

type Props = {
  pathname: string
  position?: string
}

type NavLinkProps = {
  to: string
  logo?: boolean
  children?: JSX.Element | string
  base?: string
  subLink?: boolean
}

const Menubar = ({ position = 'fixed', pathname }: Props) => {
  const [hamburgerActive, setHamburgerActive] = useState(false)

  if (pathname.startsWith('/en') || pathname.startsWith('/es'))
    pathname = pathname.slice(3)

  if (pathname === '' || pathname[pathname.length - 1] !== '/')
    pathname = `${pathname}/`

  const NavLink = ({
    to,
    logo,
    children,
    base,
    subLink = false,
  }: NavLinkProps) => (
    <Link
      to={to}
      className={cn(
        !subLink && st.link,
        Boolean(!logo && !subLink) && 'icon-menubar-link',
        to === '/'
          ? pathname === '/' && st.active
          : pathname.includes(base || to) && st.active
      )}
      onClick={() => setHamburgerActive(false)}
    >
      {children}
    </Link>
  )

  const [active, setActive] = useState(false)
  useEffect(() => {
    setActive(false)
    if (pathname !== '/' || position !== 'fixed') return
    const hero = document.getElementById('hero')
    const onScroll = () => {
      setActive(window.scrollY >= hero.offsetHeight)
    }
    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, [pathname])

  const { t } = useLanguage()

  return (
    <div
      className={cn(
        st.core,
        st[position],
        pathname === '/' && position === 'fixed' && !active && st.hidden,
        hamburgerActive && st.active
      )}
    >
      {position === 'fixed' && (
        <div className={st.logo}>
          <NavLink to="/" logo></NavLink>
        </div>
      )}
      <nav itemScope itemType="http://schema.org/SiteNavigationElement">
        <ul>
          <li>
            <NavLink to="/">Home</NavLink>
          </li>
          <li>
            <NavLink
              to={translatePath('/empreendimentos/lancamentos/')}
              base={translatePath('/empreendimentos/')}
            >
              {t('MenuBar.enterprises')}
            </NavLink>
            <ul>
              <li>
                <NavLink
                  to={translatePath('/empreendimentos/lancamentos/')}
                  subLink
                >
                  {t('MenuBar.releases')}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={translatePath('/empreendimentos/entregues/')}
                  subLink
                >
                  {t('MenuBar.handedOut')}
                </NavLink>
              </li>
              <li>
                <a
                  href="https://www.dimasconstrucoes.com.br/revendas/"
                  target="_blank"
                >
                  {t('MenuBar.resale')}
                </a>
              </li>
            </ul>
          </li>
          <li>
            <NavLink to={translatePath('/jeito-dimas/')}>
              {t('MenuBar.dimasWay')}
            </NavLink>
          </li>
          <li>
            <a href="https://dimasconstrucoes.com.br/blog/" target="_blank">
              Blog
            </a>
          </li>
          <li>
            <NavLink to={translatePath('/contato/')}>
              {t('MenuBar.contact')}
            </NavLink>
            <ul>
              <li>
                <NavLink to={translatePath('/contato/')} subLink>
                  {t('MenuBar.service')}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={translatePath('/contato/trabalhe-conosco/')}
                  subLink
                >
                  {t('MenuBar.workWithUs')}
                </NavLink>
              </li>
              <li>
                <NavLink to={translatePath('/contato/faq/')} subLink>
                  FAQ
                </NavLink>
              </li>
            </ul>
          </li>
          <li className={st.login}>
            <span className="icon-user">{t('MenuBar.login')}</span>
            <ul>
              <li>
                <a
                  href="https://app.winker.com.br/intra/default/login?wl=dimas"
                  target="_blank"
                >
                  {t('MenuBar.customerPortal')}
                </a>
              </li>
              <li>
                <NavLink to={translatePath('/acesse-condominio/')} subLink>
                  {t('MenuBar.accessYourCondominium')}
                </NavLink>
              </li>
            </ul>
          </li>
          <li className={st.globo}>
            <span className="icon-Globo"></span>
            <ul className={st.switchLanguage}>
              <li>
                <a
                  href="/"
                  className={
                    process.env.GATSBY_LANGUAGE === 'pt' ? st.active : undefined
                  }
                >
                  PT
                </a>
              </li>
              <li>
                <a
                  href="/en/"
                  className={
                    process.env.GATSBY_LANGUAGE === 'en' ? st.active : undefined
                  }
                >
                  EN
                </a>
              </li>
              <li>
                <a
                  href="/es/"
                  className={
                    process.env.GATSBY_LANGUAGE === 'es' ? st.active : undefined
                  }
                >
                  ES
                </a>
              </li>
            </ul>
          </li>
        </ul>
        <button
          className={st.close}
          onClick={() => setHamburgerActive(false)}
        ></button>
      </nav>
      {/*<button className={cn(st.searchBtn, 'icon-search')}></button>*/}
      <button
        className={cn(st.hamburgerBtn, 'icon-hamburger')}
        onClick={() => setHamburgerActive(true)}
      ></button>
    </div>
  )
}

export default (props: Props) =>
  props.position === 'absolute' ? (
    <div>
      <Menubar {...props} />
    </div>
  ) : (
    <header>
      <Menubar {...props} />
    </header>
  )

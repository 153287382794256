import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import * as st from '@assets/styl/animatedLogo.module.styl'

export default () => {
  const [ready, setReady] = useState(false)

  useEffect(() => {
    setTimeout(() => setReady(true), 300)
  }, [])

  return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 908.69361 895.19292" className={cn(ready && st.active)}>
    <defs>
      <mask id="mask-line1">
        <rect x="467.23618" width="441.45743" height="336" fill="#eae0d6" />
      </mask>
      <mask id="mask-line2">
        <rect y="666.12212" width="441.45743" height="336" fill="#eae0d6" />
      </mask>
      <mask id="mask-D">
        <polyline className={st.mask} points="229.48 478.729 243.565 478.729 251.707 478.729 260.065 478.729 266.493 478.729 274.85 477.444 281.707 474.015 286.85 469.944 290.065 465.658 292.636 459.229 293.922 453.229 293.922 445.729 291.779 439.086 288.136 433.301 284.493 428.372 280.207 423.872 273.565 420.658 264.136 419.372 257.707 419.372 251.279 419.372 245.493 419.372 240.35 419.372 233.493 419.372 231.993 429.658 232.207 435.872 232.636 441.444 232.422 447.658 232.422 453.015 232.207 458.158 232.207 465.015 232.636 486.015" fill="none" stroke="#eae0d6" strokeMiterlimit="10" strokeWidth="10" transform="translate(109.57829 75.41359)" />
      </mask>
      <mask id="mask-I">
        <polyline className={st.mask} points="349.842 482.284 349.842 474.658 349.842 467.586 349.842 460.944 349.842 454.086 349.842 447.444 349.842 441.229 349.842 434.801 349.842 428.586 349.842 422.586 349.842 416.244" fill="none" stroke="#eae0d6" strokeMiterlimit="10" strokeWidth="10" />
      </mask>
      <mask id="mask-M">
        <polyline className={st.mask} points="412.207 482.284 412.207 474.658 412.207 468.444 412.207 461.372 412.207 454.301 412.207 447.444 412.207 440.801 410.707 433.944 412.207 424.301 411.457 412.086 420.565 435.658 423.993 442.944 427.636 450.444 431.065 457.729 433.85 463.301 436.207 470.586 444.565 486.658 448.422 465.658 452.85 457.086 455.493 450.872 459.565 443.658 462.779 437.015 465.131 430.086 472.207 413.158 472.207 437.801 472.207 445.515 472.207 452.229 472.207 458.944 472.207 466.944 472.207 475.158 472.207 482.244" fill="none" stroke="#eae0d6" strokeMiterlimit="10" strokeWidth="10" transform="translate(109.57829 75.41359)" />
      </mask>
      <mask id="mask-A">
        <polyline className={st.mask} points="588.993 485.586 583.85 474.872 580.052 467.457 575.707 458.28 572.31 450.872 569.065 443.158 565.636 436.086 561.35 428.158 555.993 410.372 550.85 432.229 548.279 438.015 544.85 445.515 541.779 452.586 537.779 460.301 534.779 467.586 531.779 474.444 526.636 485.586" fill="none" stroke="#eae0d6" strokeMiterlimit="10" strokeWidth="10" transform="translate(109.57829 75.41359)" />
      </mask>
      <mask id="mask-a2">
        <polyline className={st.mask} points="536.457 461.406 548.029 461.406 555.743 461.406 562.117 461.406 568.279 461.406 578.886 461.406" fill="none" stroke="#eae0d6" strokeMiterlimit="10" strokeWidth="10" />
      </mask>
      <mask id="mask-S">
        <polyline className={st.mask} points="632.048 478.515 638.707 478.515 644.493 478.515 650.065 478.515 656.636 478.515 662.279 478.515 667.422 478.515 672.35 477.229 675.779 474.658 679.207 470.801 680.922 466.086 680.922 462.015 679.422 456.872 675.993 453.015 670.636 449.244 663.565 449.082 657.993 449.244 651.779 449.244 647.065 449.244 641.493 447.979 636.779 443.801 633.565 439.515 632.048 433.515 633.993 427.944 637.207 423.658 643.207 420.015 650.707 420.015 656.97 420.015 662.065 420.015 668.279 420.015 673.422 420.015 678.782 420.015" fill="none" stroke="#eae0d6" strokeMiterlimit="10" strokeWidth="10" transform="translate(109.57829 75.41359)" />
      </mask>
    </defs>
    <path id="line1" mask="url(#mask-line1)" d="M1007.332,75.41359,671.69559,410.44405h10.941l335.63529-335.0293Z" transform="translate(-109.57829 -75.41359)" fill="#fff" />
    <path id="line2" mask="url(#mask-line2)" d="M445.21473,635.57605,109.57829,970.60651h10.941l335.6353-335.0293Z" transform="translate(-109.57829 -75.41359)" fill="#fff" />
    <path id="D" mask="url(#mask-D)" d="M373.89259,492.81305h-34.834v56.509l6.468-5.073v-45.255h28.366a26.252,26.252,0,0,1,26.363,26.082v1.065a24.966,24.966,0,0,1-25.072,24.8h-27.89l-7.088,6.181h34.982a31.153,31.153,0,0,0,31.252-30.982v-1.064a32.439,32.439,0,0,0-32.542-32.261" fill="#fff" />
    <rect id="I" mask="url(#mask-I)" x="346.5763" y="416.20546" width="6.532" height="66.079" fill="#fff" />
    <path id="M" mask="url(#mask-M)" d="M551.77258,547.84505l-27.689-56.187h-5.35v66h6.2v-50.233l24.639,50.233h4.236l24.729-50.252v50.252h6.2v-66h-5.445Z" transform="translate(-109.57829 -75.41359)" fill="#fff" />
    <path id="A" mask="url(#mask-A)" d="M664.55457,491.61805l-30.279,66.08h7.036l8.172-17.752h35.172l8.17,17.752h7.037l-30.277-66.08Zm-15.071,48.328,17.589-38.463,17.583,38.463Z" transform="translate(-109.57829 -75.41359)" fill="#fff" />
    <polygon id="a2" mask="url(#mask-a2)" points="542.758 458.28 539.905 464.532 575.077 464.532 572.31 458.28 542.758 458.28" fill="#fff" />
    <path id="S" mask="url(#mask-S)" d="M775.68259,520.90106h-19.222a11.573,11.573,0,0,1-11.421-11.42v-.536c0-6.17,5.053-10.822,11.755-10.822h31.566v-5.945h-31.566c-10.09,0-17.7,7.209-17.7,16.767v.536a17.626,17.626,0,0,0,14.941,17.166v.2h21.646c6.593,0,12.376,5.637,12.376,12.065v.537c0,6.083-5.482,11.418-11.731,11.418h-34.7v5.946h34.7c9.416,0,17.675-8.114,17.675-17.365v-.537c0-9.593-8.56-18.009-18.32-18.009" transform="translate(-109.57829 -75.41359)" fill="#fff" />
  </svg>
}
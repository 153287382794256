import React from 'react'
import { Slider } from '@components'
import * as st from '@assets/styl/testimonials.module.styl'
import { useLanguage } from '../hooks'
export default () => {
  const { t } = useLanguage()
  return (
    <section className={st.core}>
      <div>
        <div>
          <h3>{t('Testimonials.title')}</h3>
          <p>{t('Testimonials.paragraph')}</p>
          {/*<Link to="/depoimentos/" className="btn">Outros depoimentos</Link>*/}
        </div>
        <Slider classes={{ arrow: st.arrow }} dots>
          <div className={st.slide}>
            <div>
              <iframe
                src="https://www.youtube.com/embed/viO_FskcPCU"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
            <h4>{t('Testimonials.spot')}</h4>
            <h5>{t('Testimonials.checkTestimonial')}</h5>
          </div>
          <div className={st.slide}>
            <div>
              <iframe
                src="https://www.youtube.com/embed/QyJYtWsWXiE"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
            <h4>{t('Testimonials.garden')}</h4>
            <h5>{t('Testimonials.visit')}</h5>
          </div>
          <div className={st.slide}>
            <div>
              <iframe
                src="https://www.youtube.com/embed/mXJkO0lMJFk"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
            <h4>{t('Testimonials.dimasExperience')}</h4>
            <h5>{t('Testimonials.seeCustomers')}</h5>
          </div>
        </Slider>
      </div>
    </section>
  )
}

import React from 'react'
import Helmet from 'react-helmet'
import { useLanguage } from '../hooks'

type Props = {
  title?: string
  pathname: string
  description?: string
}

const path = (pathname: string) => {
  if (process.env.GATSBY_LANGUAGE === 'en') return '/en' + pathname
  else if (process.env.GATSBY_LANGUAGE === 'es') return '/es' + pathname
  else return pathname
}

const defaultDescription =
  'Nosso nome entrega o que é imutável. O que é nossa história. O Nosso DNA. Conheça o jeito Dimas de ser.'

const Head = ({
  title,
  pathname,
  description = '',
}: Props) => {
  const { t } = useLanguage()

  const isHomePage = pathname === '/'
  const translatedTitle = isHomePage ? t('Head.title') : title
  const translatedDescription = isHomePage ? t('Head.defaultDescription') : description

  return (
    <Helmet>
      <meta
        property="og:image"
        itemProp="image"
        content="https://www.dimasconstrucoes.com.br/opengraph.jpg"
      />
      <meta
        name="twitter:image"
        content="https://www.dimasconstrucoes.com.br/opengraph.jpg"
      />
      <title>{translatedTitle}</title>
      <meta property="og:title" content={translatedTitle} />
      <meta property="og:site_name" content="Dimas Construções" />
      <meta name="description" content={translatedDescription} />
      <meta
        property="og:description"
        content={translatedDescription}
      />
      <link
        rel="canonical"
        href={'https://www.dimasconstrucoes.com.br' + path(pathname)}
      />
      <meta
        property="og:url"
        content={'https://www.dimasconstrucoes.com.br' + path(pathname)}
      />
      <link rel="shortcut icon" href="/favicon.ico" type="image/x-icon" />
      <meta property="og:locale" content="pt_BR" />
      <meta charSet="utf-8" />
      <meta name="keywords" content="Dimas Construções" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
    </Helmet>
  )
}

export default Head

import i18next from 'i18next'
import React, { ReactNode, createContext } from 'react'
import { resources } from '../translations'

i18next.init({
  lng: process.env.GATSBY_LANGUAGE || 'pt',
  resources,
})

export const context = createContext<{
  t: (key: string, config?: { returnObjects?: boolean }) => any
}>({
  t: i18next.t,
})

export function LanguageProvider({ children }: { children: ReactNode }) {
  return (
    <context.Provider value={{ t: i18next.t }}>{children}</context.Provider>
  )
}

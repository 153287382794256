import React, { Children, useEffect, useRef, useState } from 'react'
import cn from 'classnames'
import * as st from '@assets/styl/slider.module.styl'

type Props = {
  children: Array<JSX.Element>,
  slidesToShow?: number
  finite?: boolean,
  dots?: boolean,
  classes?: {
    root?: string,
    arrow?: string,
    dots?: string
  },
  responsiveSize?: number
}

function copy(arr: Array<any>) {
  return arr.concat(arr).concat(arr)
}

function translate(width: number, current: number, slidesToShow: number, offset: number) {
  if (!width) return `${-100 * current}%`
  return `${-width / slidesToShow * current + offset}px`
}

const Slider = ({ children, slidesToShow: _slidesToShow = 1, finite = false, dots, classes = {}, responsiveSize = 500 }: Props) => {
  const [slidesToShow, setSlidesToShow] = useState(1)
  const slideCount = Children.count(children)
  const dotCount = Math.ceil(slideCount / slidesToShow)

  const [x, setX] = useState(0)
  const [width, setWidth] = useState(0)
  const [config, setConfig] = useState({
    current: slideCount,
    transition: .5,
    locked: false,
    index: 0
  })

  const next = () => {
    if (config.locked) return
    if (config.current === 2 * slideCount - slidesToShow) {
      setConfig({
        current: 2 * slideCount,
        transition: .5,
        index: 0,
        locked: true
      })
      setTimeout(() => {
        const newConfig = {
          current: slideCount,
          transition: 0,
          index: 0,
          locked: true
        }
        setConfig(newConfig)
        setTimeout(() => setConfig({ ...newConfig, transition: .5, locked: false }), 50)
      }, 500)
    } else {
      let slideToGo = config.current + slidesToShow
      if (slideToGo + slidesToShow >= 2 * slideCount)
        slideToGo = 2 * slideCount - slidesToShow
      const newConfig = {
        current: slideToGo,
        transition: .5,
        index: config.index + 1,
        locked: true
      }
      setConfig(newConfig)
      setTimeout(() => setConfig({ ...newConfig, locked: false }), 500)
    }
  }

  const prev = () => {
    if (config.locked) return
    if (config.current === slideCount) {
      const index = dotCount - 1
      setConfig({
        current: slideCount - slidesToShow,
        transition: .5,
        index,
        locked: true
      })
      setTimeout(() => {
        const newConfig = {
          current: 2 * slideCount - slidesToShow,
          transition: 0,
          index,
          locked: true
        }
        setConfig(newConfig)
        setTimeout(() => setConfig({ ...newConfig, transition: .5, locked: false }), 50)
      }, 500)
    } else {
      let slidesToScroll = slidesToShow
      if (config.current === 2 * slideCount - slidesToShow)
        slidesToScroll = (slideCount % slidesToShow) || slidesToShow
      const newConfig = {
        current: config.current - slidesToScroll,
        transition: .5,
        index: config.index - 1,
        locked: true
      }
      setConfig(newConfig)
      setTimeout(() => setConfig({ ...newConfig, locked: false }), 500)
    }
  }

  useEffect(() => {
    const onResize = () => {
      const wrapper: HTMLElement = ref.current
      setWidth(wrapper.offsetWidth)
      setSlidesToShow(window.innerWidth >= responsiveSize ? _slidesToShow : 1)
    }
    window.addEventListener('resize', onResize)
    onResize()
    return () => window.removeEventListener('resize', onResize)
  }, [])

  // Carrossel deslizante:
  const [startX, setStartX] = useState(0)
  const [style, setStyle] = useState({})
  const ref = useRef()

  useEffect(() => {
    if (config.locked) return
    const wrapper: HTMLElement = ref.current
    const onTouchStart = (e) => {
      const { clientX: x } = e.touches[0]
      setStartX(x)
    }
    wrapper.addEventListener('touchstart', onTouchStart)
    return () => wrapper && wrapper.removeEventListener('touchstart', onTouchStart)
  }, [config])

  useEffect(() => {
    if (config.locked) return
    const wrapper: HTMLElement = ref.current
    const onTouchMove = (e) => {
      const { clientX: x } = e.touches[0]
      setStyle({ transitionDuration: '0s' })
      setX(x - startX)
    }
    wrapper.addEventListener('touchmove', onTouchMove)
    return () => wrapper && wrapper.removeEventListener('touchmove', onTouchMove)
  }, [config, startX])

  useEffect(() => {
    if (config.locked) return
    const wrapper: HTMLElement = ref.current
    const onTouchEnd = () => {
      setStyle({})
      const threshold = x / wrapper.offsetWidth
      if (threshold <= -0.33)
        next()
      else if (threshold >= 0.33)
        prev()
      setX(0)
    }
    wrapper.addEventListener('touchend', onTouchEnd)
    return () => wrapper.removeEventListener('touchend', onTouchEnd)
  }, [config, next, prev, x])
  // =====

  return <div className={st.core}>
    <button className={cn(st.arrow, classes.arrow, finite && config.index === 0 && 'disabled')} onClick={prev}></button>
    <div className={cn(classes.root)}>
      <div className={st.wrapper} ref={ref}>
        {Children.map(copy(children), (child, index) =>
          <div key={index} style={{ transform: `translateX(${translate(width, config.current, slidesToShow, x)})`, transitionDuration: `${config.transition}s`, flex: `0 0 ${100 / slidesToShow}%`, ...style }}>
            {child}
          </div>
        )}
      </div>
    </div>
    <button className={cn(st.arrow, classes.arrow, finite && config.index === dotCount - 1 && 'disabled')} onClick={next}></button>
    {dots && <ul className={cn(st.dots, classes.dots)}>
      {Array(dotCount).fill(0).map((_, key) =>
        <li
          key={key}
          className={cn(key === config.index && st.active)}
          onClick={() => {
            if (config.locked) return
            const newConfig = {
              current: slideCount + key * slidesToShow,
              transition: .5,
              locked: true,
              index: key
            }
            if (key === dotCount - 1)
              newConfig.current = 2 * slideCount - slidesToShow
            setConfig(newConfig)
            setTimeout(() => setConfig({ ...newConfig, locked: false }), 500)
          }}
        >{key}</li>
      )}
    </ul>}
  </div>
}

export default Slider
import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import * as st from '@assets/styl/footer.module.styl'
import { useLanguage } from '../hooks'
import { translatePath } from '../translations'

export default () => {
  const data = useStaticQuery(graphql`
    query {
      allAddresses {
        nodes {
          location
        }
      }
      allConfigurations {
        nodes {
          phone
          facebook
          instagram
          linkedin
          youtube
        }
      }
    }
  `)
  const location = data.allAddresses.nodes[0].location.split(/(.*?\d+ )/)
  const socials = data.allConfigurations.nodes[0]
  const { t } = useLanguage()

  return (
    <footer className={st.core}>
      <div>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to={translatePath('/empreendimentos/lancamentos/')}>
              {t('Footer.enterprises')}
            </Link>
            <ul>
              <li>
                <Link to={translatePath('/empreendimentos/lancamentos/')}>
                  {t('Footer.releases')}
                </Link>
              </li>
              <li>
                <Link to={translatePath('/empreendimentos/entregues/')}>
                  {t('Footer.handedOut')}
                </Link>
              </li>
              <li>
                <a
                  href="https://www.dimasconstrucoes.com.br/revendas/"
                  target="_blank"
                >
                  {t('Footer.resale')}
                </a>
              </li>
            </ul>
          </li>
          <li>
            <Link to={translatePath('/jeito-dimas/')}>
              {t('Footer.dimasWay')}
            </Link>
          </li>
          <li>
            <a href="https://dimasconstrucoes.com.br/blog/" target="_blank">
              Blog
            </a>
          </li>
          <li>
            <Link to={translatePath('/contato/')}>{t('Footer.contact')}</Link>
            <ul>
              <li>
                <Link to={translatePath('/contato/')}>
                  {t('Footer.service')}
                </Link>
              </li>
              <li>
                <Link to={translatePath('/contato/trabalhe-conosco/')}>
                  {t('Footer.workWithUs')}
                </Link>
              </li>
              <li>
                <Link to={translatePath('/contato/faq/')}>FAQ</Link>
              </li>
            </ul>
          </li>
          <li>
            <a href="https://dimas.cvcrm.com.br/cliente/" target="_blank">
              {t('Footer.login')}
            </a>
            <ul>
              <li>
                <a
                  href="https://app.winker.com.br/intra/default/login?wl=dimas"
                  target="_blank"
                >
                  {t('Footer.customerPortal')}
                </a>
              </li>
              <li>
                <Link to={translatePath('/acesse-condominio/')}>
                  {t('Footer.accessYourCondominium')}
                </Link>
              </li>
            </ul>
          </li>
          <li>
            <ul>
              <li>
                <Link to={translatePath('/politica-de-privacidade/')}>
                  {t('Footer.privacyPolicy')}
                </Link>
              </li>
              <li>
                <Link to={translatePath('/termos-de-uso/')}>
                  {t('Footer.termsofUse')}
                </Link>
              </li>
            </ul>
          </li>
        </ul>
        <div>
          <span className={st.phone}>{socials.phone}</span>
          <span className={st.address}>
            {location[1]}
            <br />
            {location[2]}
          </span>
          <ul>
            <li>
              <a
                className="icon-facebook"
                href={socials.facebook}
                target="_blank"
              ></a>
            </li>
            <li>
              <a
                className="icon-instagram"
                href={socials.instagram}
                target="_blank"
              ></a>
            </li>
            <li>
              <a
                className="icon-linkedin"
                href={socials.linkedin}
                target="_blank"
              ></a>
            </li>
            <li>
              <a
                className="icon-youtube"
                href={socials.youtube}
                target="_blank"
              ></a>
            </li>
          </ul>
        </div>
      </div>
      <div>
        <a href="https://www.tiki.com.br" target="_blank">
          Tiki
        </a>
      </div>
    </footer>
  )
}

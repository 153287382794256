import React from 'react'
import { Tabs } from '@components'
import { LazyImageFull, ImageState } from 'react-lazy-images'

import img1 from '@assets/img/pernas.jpg'
import img2 from '@assets/img/desenvolvimento-cidade.jpg'
import img3 from '@assets/img/dnex-fachada.jpg'
import img4 from '@assets/img/placas-parede.jpg'
import img5 from '@assets/img/mulher-bike.jpg'
import { useLanguage } from '../hooks'

export default () => {
  const { t } = useLanguage()
  return (
    <Tabs
      title={
        <>
          {t('ValuesTabs.title.1')} <b>{t('ValuesTabs.title.2')}</b>{' '}
          {t('ValuesTabs.title.3')}
        </>
      }
      items={[
        t('ValuesTabs.items.1'),
        t('ValuesTabs.items.2'),
        t('ValuesTabs.items.3'),
        t('ValuesTabs.items.4'),
        t('ValuesTabs.items.5'),
      ]}
    >
      <div>
        <LazyImageFull src={img1}>
          {({ imageState, ref }) => (
            <div
              ref={ref}
              style={{
                backgroundImage: `url(${
                  imageState === ImageState.LoadSuccess ? img1 : ''
                })`,
              }}
            ></div>
          )}
        </LazyImageFull>
        <div>
          <div>
            <h4>{t('ValuesTabs.buildForPeople')}</h4>
            <p>{t('ValuesTabs.coreValue')}</p>
          </div>
        </div>
      </div>

      <div>
        <LazyImageFull src={img2}>
          {({ imageState, ref }) => (
            <div
              ref={ref}
              style={{
                backgroundImage: `url(${
                  imageState === ImageState.LoadSuccess ? img2 : ''
                })`,
              }}
            ></div>
          )}
        </LazyImageFull>
        <div>
          <div>
            <h4>{t('ValuesTabs.buildForCity')}</h4>
            <p>{t('ValuesTabs.positivelyImpact')}</p>
          </div>
        </div>
      </div>

      <div>
        <LazyImageFull src={img3}>
          {({ imageState, ref }) => (
            <div
              ref={ref}
              style={{
                backgroundImage: `url(${
                  imageState === ImageState.LoadSuccess ? img3 : ''
                })`,
              }}
            ></div>
          )}
        </LazyImageFull>
        <div>
          <div>
            <h4>{t('ValuesTabs.buildForTimeless')}</h4>
            <p>{t('ValuesTabs.developAproject')}</p>
          </div>
        </div>
      </div>

      <div>
        <LazyImageFull src={img4}>
          {({ imageState, ref }) => (
            <div
              ref={ref}
              style={{
                backgroundImage: `url(${
                  imageState === ImageState.LoadSuccess ? img4 : ''
                })`,
              }}
            ></div>
          )}
        </LazyImageFull>
        <div>
          <div>
            <h4>{t('ValuesTabs.buildForTechnology')}</h4>
            <p>{t('ValuesTabs.professionalProvision')}</p>
          </div>
        </div>
      </div>

      <div>
        <LazyImageFull src={img5}>
          {({ imageState, ref }) => (
            <div
              ref={ref}
              style={{
                backgroundImage: `url(${
                  imageState === ImageState.LoadSuccess ? img5 : ''
                })`,
              }}
            ></div>
          )}
        </LazyImageFull>
        <div>
          <div>
            <h4>{t('ValuesTabs.buildForCulture')}</h4>
            <p>{t('ValuesTabs.weWantToSay')}</p>
          </div>
        </div>
      </div>
    </Tabs>
  )
}

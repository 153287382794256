import React, { cloneElement, useState } from 'react'
import cn from 'classnames'
import * as st from '@assets/styl/tabs.module.styl'

type Props = {
  title: JSX.Element,
  items: Array<string>,
  children: JSX.Element | Array<JSX.Element>
}

export default ({ title, items, children }: Props) => {
  const [active, setActive] = useState(0)

  return <section className={st.core}>
    <div><div>
      <h3>{title}</h3>
      <ul>
        {items.map((item, key) =>
          <li
            key={key}
            className={cn(active === key && st.active)}
            onClick={() => setActive(key)}
          >{item}</li>)}
        <li style={{ top: `${active / items.length * 100}%` }}></li>
      </ul>
    </div></div>
    <div>
      {React.Children.map(children, (child, key) =>
        cloneElement(child, { className: cn(child.props.className, active === key && st.active) })
      )}
    </div>
  </section>
}
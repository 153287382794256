// extracted by mini-css-extract-plugin
export var core = "menubar-module--core--rzyTa";
export var searchBtn = "menubar-module--search-btn--VVbTb";
export var hamburgerBtn = "menubar-module--hamburger-btn--RVm4b";
export var absolute = "menubar-module--absolute--NCYlw";
export var fixed = "menubar-module--fixed--9MWjZ";
export var hidden = "menubar-module--hidden--MPsZU";
export var logo = "menubar-module--logo--dYCDq";
export var login = "menubar-module--login--IJCXa";
export var globo = "menubar-module--globo--kH4mL";
export var link = "menubar-module--link--ayQYy";
export var active = "menubar-module--active--2bbz4";
export var close = "menubar-module--close--ToMbv";
export var switchLanguage = "menubar-module--switchLanguage--oqMMH";
import React, { useEffect, useState } from 'react'
import * as st from '@assets/styl/modal.module.styl'
import cn from 'classnames'

type Props = {
  open?: boolean,
  close: () => void,
  className: string,
  children: JSX.Element | Array<JSX.Element>,
  noCloseButton?: boolean,
  clientOnly?: boolean
}

export default ({ open, close, className, children, noCloseButton = false, clientOnly = false }: Props) => {
  const [active, setActive] = useState(false)
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    if (open) {
      setActive(true)
      setTimeout(() => setVisible(true), 50)
    } else {
      setVisible(false)
      setTimeout(() => setActive(false), 300)
    }
  }, [open])

  const overlayClose = (e) => {
    if (e.target === e.currentTarget) close()
  }

  return <div onClick={overlayClose} className={cn(st.overlay, active && st.active, visible && st.open, noCloseButton && st.noCloseButton)}>
    <div onClick={overlayClose}>
      {!noCloseButton && <button className={st.close} onClick={close}></button>}
      <div className={className}>{Boolean(!clientOnly || active) && children}</div>
    </div>
  </div>
}